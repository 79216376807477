<template>
  <div class="innerPageMainContent">
  <b-card>
  <div class="topicGroup">
    <div class="topicItem">
      <h4>壹、查核建案/建商/銷售方式</h4>
      <div class="topicItemList">
        <b-form inline>
          <label for="inline-form-input-name" class="mr-2">建案名稱:</label>
          <b-form-input id="inline-form-input-name-2" class="mb-2 mr-sm-2 mb-sm-0"
            v-model="form.BuildCase"></b-form-input>
        </b-form>
      </div>
      <div class="topicItemList">
        <b-form inline>
          <label class="mr-2">建商名稱:</label>
          <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.BuilderName"></b-form-input>
        </b-form>
      </div>
      <div class="topicItemList">
        <b-form inline>
          <b-form inline>
            <b-form-checkbox class="text-danger" value="0" v-model="form.BuildYear" unchecked-value=""><span class="text-danger">113 上半年</span></b-form-checkbox>
            <b-form-checkbox value="1" v-model="form.BuildYear" unchecked-value="">112 下半年</b-form-checkbox>
            <b-form-checkbox value="2" v-model="form.BuildYear" unchecked-value="">112 上半年</b-form-checkbox>
          </b-form>
          <label class="mr-2">廣告建案</label>
        </b-form>
      </div>
      <div class="topicItemList">
        <b-form inline>
          <label class="mr-2">銷售方式:</label>
          <b-form inline>
            <b-form-checkbox value="0" v-model="form.Is_AgencySales" unchecked-value="">自售</b-form-checkbox>
            <b-form-checkbox value="1" v-model="form.Is_AgencySales" unchecked-value="">代銷</b-form-checkbox>
          </b-form>
        </b-form>
      </div>
      <div class="topicItemList">
        <b-form inline>
          <label class="mr-2">是否設立銷售中心:</label>
          <b-form inline>
            <b-form-checkbox value="0" v-model="form.Has_SellingCentre" unchecked-value="">無</b-form-checkbox>
            <b-form-checkbox value="1" v-model="form.Has_SellingCentre" unchecked-value="">有</b-form-checkbox>
          </b-form>
          <label class="mr-2">，代銷業名稱：</label>
          <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.AgencySales_Name"></b-form-input>
        </b-form>
      </div><div class="topicItemList">
        <b-form inline>
          <label class="mr-2">是否設立樣品屋:</label>
          <b-form inline>
            <b-form-checkbox value="0" v-model="form.Has_Sample" unchecked-value="">無</b-form-checkbox>
            <b-form-checkbox value="1" v-model="form.Has_Sample" unchecked-value="">有</b-form-checkbox>
          </b-form>
        </b-form>
      </div>
    </div>
    <div class="topicItem">
      <h4>貳、地址</h4>
      <div class="topicItemList">
        <b-form-group label="銷售中心地址：" label-for="nested-street" label-cols-sm="2" label-align-sm="right">
          <b-form-input id="nested-street" v-model="form.SellingCentre_addr"></b-form-input>
        </b-form-group>

        <b-form-group label="建商地址：" label-for="nested-street" label-cols-sm="2" label-align-sm="right">
          <b-form-input id="nested-street" v-model="form.Builder_addr"></b-form-input>
        </b-form-group>
        <!-- <b-form-group label="設立日期：" label-for="nested-city" label-cols-sm="2" label-align-sm="right">
          <b-input-group class="mb-2 dateInput">
            <b-form-input
              v-model="dateTime.FoundDate"
              type="text"
              placeholder="YYYMMDD，例如:0991231"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="form.FoundDate"
                button-only
                right
                locale="zh-TW"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group> -->

        <!-- <b-form-group label="代銷業名稱：" label-for="nested-state" label-cols-sm="2" label-align-sm="right">
          <b-form-input id="nested-state" v-model="form.AgencySales_Name"></b-form-input>
        </b-form-group> -->
        <b-form-group label="代銷業地址：" label-for="nested-state" label-cols-sm="2" label-align-sm="right">
          <b-form-input id="nested-state" v-model="form.AgencySales_addr"></b-form-input>
        </b-form-group>
      </div>
    </div>
  </div>
</b-card>
</div>
</template>
<script>
//import DatePicker from 'vue2-datepicker';
//import 'vue2-datepicker/index.css';

export default {
  name: 'page1',
  components: { },
  data() {
    return {
      pageName: '基本資料填寫',
      FoundDateValue: '',
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    }
  },
  watch: {
    "dateTime.FoundDate": function (val) {
      if(val.length == 7){
        let date = this.str2dateStr(val)
        if(this.IsDateValid(date)){
          this.$store.commit('increment',{key: "FoundDate" ,value :date})
          //this.$store.commit('incrementDateTime',{key: "FoundDate" ,value :date})
        }
        else{
          alert("日期格式錯誤")
        }
      }
    },
  },
  created() {
    //console.log("created method was called")
    this.userSignCheck();
    //this.formdata.InspectDate = this.formdata.InspectDate.replace("T", " ");

    if(!this.IsNull(this.form.FoundDate)){
      //console.log("FoundDate ",this.form.FoundDate)
      this.value = this.form.FoundDate;
    }
  },
  methods: {
    changeObj(key,evt){
        if(evt != "undefined"){
          this.$store.commit('increment',{key: key ,value :evt})
        }
        
    },
    
  },
};
</script>