<template>
  <div class="innerPageMainContent">
    <b-card>
      <div class="topicGroup">
        <div class="topicItem">
          <h4>肆、業者陳述意見</h4>
          <div class="topicItemList">
            <b-form-textarea id="textarea" v-model="form.Statement" placeholder="請輸入業者陳述意見" rows="3"
              max-rows="6"></b-form-textarea>
            <b-form inline class="pt-3">
              <label class="mr-sm-2">陳述人：</label>
              <b-button variant="outline-success" @click="Declarantimg" style="margin-right:10px;">親筆簽名欄位(簽章)</b-button>
              <b-img :src="form.Declarant" fluid style="width:100px; height: 50px;"></b-img>
              <label class="mr-sm-2">任職於</label>
                <b-form-checkbox value="0" v-model="form.BuilderConsignment" unchecked-value="0">建商</b-form-checkbox>
                <b-form-checkbox value="1" v-model="form.BuilderConsignment" unchecked-value="0">代銷</b-form-checkbox>
              <b-modal id="modal-1" title="親筆簽名欄位" size="lg" @hidden="closesignmodal" hide-footer>
                <vue-esign ref="esign" :height="500"  :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
                  :bgColor.sync="bgColor" :isClearBgColor="false" style="padding: 0 !important;" />
                <b-button variant="danger" class="mx-1" @click="handleReset"><b-icon icon="trash-fill"
                    class="mr-1"></b-icon>清空</b-button>
                <b-button variant="success" class="mx-1" @click="handleGenerate"><b-icon icon="printer-fill"
                    class="mr-1"></b-icon>簽名確認</b-button>
                <!-- <img :scr="imgUrl" style="width:800px; height: 500px;" /> -->
                  <b-img :src="imgSrc" fluid style="width:800px; height: 500px;"></b-img>
                <div>
                  <!-- <img :scr="formdata.Declarant" style="width:800px; height: 500px;" /> -->

                </div>
              </b-modal>
              <b-modal id="modal-2" modal-class="modal-fullscreen" title="親筆簽名欄位" size="lg" @hidden="closesignmodal" hide-footer>
                <vue-esign ref="esign" :height="300"  :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
                  :bgColor.sync="bgColor" :isClearBgColor="false" style="padding: 0 !important;" />
                <b-button variant="danger" class="mx-1" @click="handleReset"><b-icon icon="trash-fill"
                    class="mr-1"></b-icon>清空</b-button>
                <b-button variant="success" class="mx-1" @click="handleGenerate"><b-icon icon="printer-fill"
                    class="mr-1"></b-icon>簽名確認</b-button>
                <!-- <img :scr="imgUrl" style="width:800px; height: 500px;" /> -->
                <b-img id="imgSrc" :src="imgSrc" fluid></b-img>
                <div>
                  <!-- <img :scr="formdata.Declarant" style="width:800px; height: 500px;" /> -->

                </div>
              </b-modal>
            </b-form>
            <b-form inline class="pt-3">
              <label class="mx-sm-2">職稱：</label>
              <b-form-input class="mb-2 mr-md-2 mb-md-0" placeholder="請輸入職稱"
                v-model="form.JobTitle" maxlength="40"></b-form-input>
              <label class="mx-sm-2">連絡電話：</label>
              <b-form-input class="mb-2 mr-md-2 mb-md-0" placeholder="請輸入連絡電話"
                v-model="form.Phone" maxlength="16"></b-form-input>
            </b-form>
            <!-- <b-form inline class="pt-3">
              <label class="mx-sm-2">E-mail：</label>
              <b-form-input class="mb-2 mr-md-2 mb-md-0" placeholder="請輸入E-mail"
                v-model="form.Email"
                ></b-form-input>
            </b-form> -->
          </div>
          <div class="topicItemList bg-dark text-white rounded">
            <b-row align-h="between" align-v="center" class="px-2 pb-3">
              <b-col cols="auto">查核人員：</b-col>
              <b-col cols="auto"><b-button variant="primary" size="sm" class="mr-2" @click="addbox"><b-icon
                    icon="plus"></b-icon>欄位</b-button><b-button variant="primary" size="sm" @click="addbox2"><b-icon
                    icon="plus"></b-icon>自訂欄位</b-button></b-col>
            </b-row>
            <b-row class="px-4 pt-2" cols="1" cols-md="4" cols-lg="6">
              <b-col v-for="item in InspectorRecord" :key="item.id">
                <div class="signBox">
                  <div class="signBoxclose"><b-icon icon="x" @click="removebox1(item)"></b-icon></div>
                  <div class="signBoxTitle">
                    <b-form-select v-model="item.Inspector_NAME" :options="options"></b-form-select>
                  </div>
                  <div class="signBoxContent">
                    <b-button v-model="item.esign" variant="outline-success"
                      @click="InspectorRecordesing(item)">親筆簽名欄位</b-button>
                  </div>
                  <b-img v-if="item.esign != ''" :src="item.esign" fluid style="width:100%; height: 50px;"></b-img>
                </div>
              </b-col>
              <b-col v-for="(item2, index) in InspectorRecord2" :key="'prefix-' + index">
                <div class="signBox">
                  <div class="signBoxclose"><b-icon icon="x" @click="removebox2(item2)"></b-icon></div>
                  <div class="signBoxTitle">
                    <b-form-input class="mb-0" placeholder="請自行輸入" v-model="item2.Inspector_NAME"></b-form-input>
                  </div>
                  <div class="signBoxContent">
                    <b-button variant="outline-success" v-model="item2.esign"
                      @click="InspectorRecordesing2(item2)">親筆簽名欄位</b-button>
                  </div>
                  <b-img v-if="item2.esign != ''" :src="item2.esign" fluid style="width:100%; height: 50px;"></b-img>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div class="topicItem">
          <!-- <h4>伍、查核結果處理</h4>
          <div class="topicItemList">
            <b-form inline class="pt-3">
                <b-row  cols="1">
                  <b-col>
                    <b-form-checkbox id="checkbox-1"  name="checkbox-1" value="1" v-model="form.Contract" unchecked-value="0">
                      預售屋買賣契約書攜回查核。
                    </b-form-checkbox>
                  </b-col>
                </b-row>
            </b-form>
          </div>
          <div class="topicItemList">
            <b-form inline class="pt-3">
                <b-row align-v="center" cols="1">
                  <b-col>
                    <b-form-checkbox id="checkbox-2" name="checkbox-2" value="1" v-model="form.MissingItems" unchecked-value="0">
                      違規缺失事項，擬請業者
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row align-v="left" cols="1">
                  <b-col>
                    <b-form-checkbox id="checkbox-2-1" name="checkbox-2-1" value="1" v-model="form.MissingWritten" unchecked-value="0" :disabled="Mdisabled">
                      書面陳述意見
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row align-v="left" cols="1">
                  <b-col>
                    <b-form-checkbox id="checkbox-2-2" name="checkbox-2-2" value="1" v-model="form.DeadlineCorrect" :disabled="Mdisabled" unchecked-value="0">
                      限期改正。
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form>
          </div>
          <div class="topicItemList">
            <b-form inline class="pt-3">
                <b-row align-v="center" cols="1">
                  <b-col>
                    <b-form-checkbox id="checkbox-3" name="checkbox-3" value="1" v-model="form.Tribunal" unchecked-value="0">
                      違規缺失事項，擬逕依法裁處。
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form>
          </div>
          <div class="topicItemList">
            <b-form inline class="pt-3">
                <b-row  cols="1">
                  <b-col>
                    <b-form-checkbox id="checkbox-4" name="checkbox-2" value="1" v-model="form.Other5" unchecked-value="0" >
                      其他：
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row  cols="1">
                  <b-col>
                    <b-form-input class="mb-2 mr-md-2 mb-md-0" placeholder="" v-model="form.Other5Content" :disabled="disabled"
                    style="width:500px;" unchecked-value="0" maxlength="200"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
          </div> -->
          <!-- <div class="topicItemList">
            <b-row  cols="2">
              <b-col >
                <b-form inline class="pt-3">
                  <label class="mr-sm-2">查核人員：</label>
                  <b-button variant="outline-success" @click="InspectorSing" style="margin-right:10px;">親筆簽名欄位(簽章)</b-button>
                </b-form> 
              </b-col>
              <b-col>
                <b-form inline class="pt-3">
                  <label class="mr-sm-2">會同人員：</label>
                  <b-button variant="outline-success" @click="AccompanyingSign" style="margin-right:10px;">親筆簽名欄位(簽章)</b-button>
                </b-form>
              </b-col>
            </b-row>
          </div> -->
          <div class="topicItem">
            <h4>伍、預售屋建案現場銷售人員名冊(請業者提供明信片)</h4>
            <div class="topicItemList">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">序</th>
                      <th scope="col">姓名</th>
                      <th scope="col">身分證字號</th>
                      <th scope="col">經紀人員證號資訊</th>
                      <th scope="col">備註</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, index) in Salesperson">
                      <b-tr :key="'Sale' + index">
                        <b-th scope="row">{{ item.Sort }}</b-th>
                        <b-td><b-form-input class="mb-0" placeholder="請自行輸入"
                            v-model="item.NAME"></b-form-input></b-td>
                        <b-td><b-form-input class="mb-0" placeholder="請自行輸入"
                            v-model="item.ID_number"></b-form-input></b-td>
                        <b-td>
                          <b-form inline class="pb-4">
                            <b-form-checkbox name="checkbox-9" value="1" unchecked-value="0"
                              v-model="item.Broker_Remark">
                              經紀人，證號：
                            </b-form-checkbox>
                            <b-form-input class="mb-2 mr-sm-2 mb-sm-0" placeholder="請輸入證號"
                              v-model="item.Broker_CN" maxlength="40" ></b-form-input>
                          </b-form>
                          <b-form inline>
                            <b-form-checkbox name="checkbox-9" value="1" unchecked-value="0"
                              v-model="item.Sales_Remark">
                              營業員，證號：
                            </b-form-checkbox>
                            <b-form-input class="mb-2 mr-sm-2 mb-sm-0" placeholder="請輸入證號"
                              v-model="item.Sales_CN" maxlength="40"></b-form-input>
                          </b-form>
                        </b-td>
                        <td>

                          <b-form inline>
                            <b-form-checkbox value="0" v-model="item.Remark">有</b-form-checkbox>
                            <b-form-checkbox value="1" v-model="item.Remark">無</b-form-checkbox>到職備查
                            <b-form-checkbox value="2" v-model="item.Remark">無經紀人員資格</b-form-checkbox>
                          </b-form>
                        </td>
                        <td>
                          <b-button variant="danger" class="mx-1" v-if="item.Sort > 1"
                            @click="Deleterow(item)"><b-icon icon="trash-fill" class="mr-1"></b-icon>刪除</b-button>
                        </td>
                      </b-tr>
                    </template>

                    <tr>
                      <td colspan="6">
                        <b-button variant="primary" block class="mr-2" @click="addrow"><b-icon
                            icon="plus"></b-icon>欄位</b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-row cols="1" cols-md="2">
                <b-col>
                  <b-form inline class="mb-2">
                    <label class="mx-2"> *本建案現場銷售人員計</label>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.Salesperson_num"></b-form-input>
                    <label class="mx-2">位，</label>
                  </b-form>

                  <b-form inline class="mb-2">
                    <label class="mx-2">其中經紀人</label>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.Borker_num"></b-form-input>
                    <label class="mx-2">位、</label>
                  </b-form>
                  <b-form inline class="mb-2">
                    <label class="mx-2">營業員</label>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.Sales_num"></b-form-input>
                    <label class="mx-2">位、</label>
                  </b-form>
                  <b-form inline class="mb-2">
                    <label class="mx-2">未具經紀人員資格</label>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.NotBorker_num"></b-form-input>
                    <label class="mx-2">位。</label>
                  </b-form>

                  <b-form inline class="mb-2">
                    <label class="mx-2">經紀人員已完成到職備查計</label>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.OnDuty_num"></b-form-input>
                    <label class="mx-2">位,</label>
                  </b-form>

                  <b-form inline>
                    <label class="mx-2">未完成到職備查計</label>
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.NotOnDuty_num"></b-form-input>
                    <label class="mx-2">位。</label>
                  </b-form>
                </b-col>
                <b-col>
                  *提醒業者未在建案現場之銷售人員亦應具備經紀人員資格·且應完成到職備查。
                </b-col>
              </b-row>

            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import imgUrl from '../assets/imgs/contact_img.png'

export default {
  data() {
    return {
      disabled:true,
      Mdisabled:true,
      inspectorModal: {},
      deleteModalShow: false,
      pageName: '業者陳述意見及查核結果',
      padWidth: window.innerWidth *0.975,
      padhength: window.innerWidth *0.5,
      imgUrl,
      count: 1,
      page: "",
      box1index: 1,
      box2index: 1,
      SalespersonInput: [],
      InspectorRecordInput: [],
      SignType:"Declarant",
      InspectorRecordindex: -1,
      InspectorRecord2index: -1,
      imgSrc: "",
      //canvas宣告區塊
      lineWidth: 4,
      lineColor: "#000000",
      bgColor: "#ffffff",
      resultImg: "",
      isCrop: true,
      //canvas宣告結束
      esing1item: [],
      selected: null,
      options: [
        { value: '內政部', text: '內政部' },
        { value: '公平交易委員會', text: '公平交易委員會' },
        { value: '行政院消保處', text: '行政院消保處' },
        { value: '財政部賦稅署所屬機關', text: '財政部賦稅署所屬機關' },
        { value: '地政局', text: '地政局' },
        { value: '消保官', text: '消保官' },
        { value: '都發局', text: '都發局' },
        { value: '住宅處', text: '住宅處' }
      ],
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
    InspectorRecord: function(){
        return this.$store.state.InspectorRecord
    },
    InspectorRecord2: function(){
        return this.$store.state.InspectorRecord2
    },
    Salesperson: function(){
        return this.$store.state.Salesperson
    },
  },
  created() {
    this.userSignCheck();
    if(this.form.Other5 == "0" || this.IsNullEmpty(this.form.Other5)){
      this.disabled=true;
    }else{
      this.disabled = false;
    }
    if(this.form.MissingItems =="0" || this.IsNullEmpty(this.form.MissingItems)){
      this.Mdisabled = true;
    }else{
      this.Mdisabled = false;
    }
    console.log("padWidth",this.padWidth);
  },
  watch:{
    "form.Other5"(val){
      if(val == "0") {
        this.disabled=true;
        this.$store.commit('increment',{key: "Other5Content" ,value :""})
      }
      else {
        this.disabled = false;
      }
    },
    "form.MissingItems"(val){
      if(val == "1"){
        this.Mdisabled = false;
        //this.$store.commit('increment',{key:"MissingWritten" ,value:"0"});
      }
      else{
        this.Mdisabled = true;
        this.$store.commit('increment',{key:"MissingWritten" ,value:"0"});
        this.$store.commit('increment',{key:"DeadlineCorrect" ,value:"0"});
      }
    }
  },  
  mounted() {
    
  },
  methods: {
    formatter_year(e){
      return String(e).substring(0,4);
    },
    formatter_key(e){
      return String(e).substring(0,30);
    },
    getModalWidth() {
      this.$nextTick(() => {
        const modalWidth = this.$refs.modalRef.$el.querySelector('.modal-dialog').offsetWidth;
        console.log(modalWidth);
      });
    },
    //Canvas
    handleReset() {
      this.$refs.esign.reset();
    },
    InspectorSing(){
      this.SignType = "Inspector";
      this.imgSrc = "";
      this.imgSrc = this.form.InspectorSign;
      if(this.padWidth > 1024){
        console.log("modal-1 open");
        this.$bvModal.show("modal-1");
      }
      else{
        console.log("modal-2 open");
        this.$bvModal.show("modal-2");
      } 
    },
    AccompanyingSign(){
      this.SignType = "Accompanying";
      this.imgSrc = "";
      this.imgSrc = this.form.AccompanyingSign;
      if(this.padWidth > 1024)this.$bvModal.show("modal-1");
      else this.$bvModal.show("modal-2");
    },
    handleGenerate() {
      this.$refs.esign.generate({ format: 'image/jpeg', quality: 0.7 }).then(res => {
        this.resultImg = res;
        if (this.SignType == "Inspector" ) {
          this.$store.commit('increment',{key: "InspectorSign" ,value :res});
          this.imgSrc = res;
        }
        else if (this.SignType == "Accompanying") {
          this.$store.commit('increment',{key: "AccompanyingSign" ,value :res});
          this.imgSrc = res;
        }
        else {
          this.$store.commit('increment',{key: "Declarant" ,value :res});
          //this.form.Declarant = res;
          this.imgSrc = res;
        }
        if (this.InspectorRecordindex != null && this.InspectorRecordindex != -1) {
          let index1 = this.InspectorRecordindex;
          this.InspectorRecord[index1].esign = res;
          this.imgSrc = res;
        }
        else if (this.InspectorRecord2index != null && this.InspectorRecord2index != -1) {
          let index2 = this.InspectorRecord2index;
          this.InspectorRecord2[index2].esign = res;
          this.imgSrc = res;
        }
        this.InspectorRecordindex = -1;
        this.InspectorRecord2index = -1;
      }).catch(err => {
        alert(err)
      })
    },
    closesignmodal() {
      this.InspectorRecordindex = -1;
      this.InspectorRecord2index = -1;
    },
    Declarantimg() {
      this.SignType ="";
      this.imgSrc = "";
      this.imgSrc = this.form.Declarant;
      if(this.padWidth > 1024){
        console.log("modal-1 open");
        this.$bvModal.show("modal-1");
      }
      else{
        console.log("modal-2 open");
        this.$bvModal.show("modal-2");
      } 
    },
    InspectorRecordesing(item) {
      //console.log("esing-item",item);
      let index = this.InspectorRecord.indexOf(item);
      this.InspectorRecordindex = index;
      this.imgSrc = "";
      this.imgSrc = item.esign;
      if(this.padWidth > 1024)this.$bvModal.show("modal-1");
      else this.$bvModal.show("modal-2");
      //console.log("InspectorRecordindex is '' ",this.InspectorRecordindex);
      //console.log("InspectorRecord2index is '' ",this.InspectorRecord2index);
      //console.log("index",index);
    },
    InspectorRecordesing2(item) {
      let index = this.InspectorRecord2.indexOf(item);
      this.InspectorRecord2index = index;
      this.imgSrc = "";
      this.imgSrc = item.esign;
      if(this.padWidth > 1024)this.$bvModal.show("modal-1");
      else this.$bvModal.show("modal-2");
      //console.log("InspectorRecordindex is '' ",this.InspectorRecordindex);
      //console.log("InspectorRecord2index is '' ",this.InspectorRecord2index);
    },
    addbox() {
      this.box1index += 1;
      let obj = {
        id: this.box1index,
        Inspector_NAME: "",
        esign: "",
      };
      this.InspectorRecord.push(obj);
    },
    addbox2() {
      this.box2index += 1;
      let obj = {
        id: this.box2index,
        Inspector_NAME: "",
        esign: "",
      };
      this.InspectorRecord2.push(obj);
    },
    addrow() {
      this.count = this.count + 1;
      let obj = {
        Sort: this.count,
        Form_ID: "",
        NAME: "",
        ID_number: "",
        Broker_CN_YEAR: "",
        Broker_CN: "",
        Broker_Remark: "",
        Sales_CN_YEAR: "",
        Sales_CN: "",
        Sales_Remark: "",
        Remark: "0",
      };
      this.Salesperson.push(obj);
    },
    Deleterow(item) {
      let index = this.Salesperson.indexOf(item);
      if (index > -1 && index < this.Salesperson.length) {
        this.Salesperson.splice(index, 1);
        for (var i = index; i < this.Salesperson.length; i++) {
          this.Salesperson[i].Sort -= 1;
        }
        this.count = this.Salesperson[this.Salesperson.length - 1].Sort;
      }
    },
    removebox1(item) {
      //console.log("removebox1 with called");
      let index = this.InspectorRecord.indexOf(item);
      if (index > -1)
        this.InspectorRecord.splice(index, 1);
    },
    removebox2(item) {
      let index = this.InspectorRecord2.indexOf(item);
      if (index > -1)
        this.InspectorRecord2.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
@media screen and (orientation: landscape) {
  #sign {
    padding: 0.1rem 1rem;
  }
  

}
#modal-2{
  padding: 0 !important;
}
.modal-body{
  padding: 0 !important;
}
.modal-fullscreen .modal-dialog{
  max-width: 100%;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  min-height: 100%;
  height: auto;
  margin: 0;
}
#imgSrc {
  height: 300px;
  width: 100%;
}
</style>

