<template>
<div class="innerPage">
    <div class="innerPageHead">
    <b-container>
        <b-breadcrumb :items="items"></b-breadcrumb>
    </b-container>
    </div>
    <div class="innerPageContent">
    <b-container>
        <div class="innerPageContentTitle">
        <b-row class="pb-4">
            <template v-if="selectedKeys==1">
                <b-col cols="auto"><b-form-input class="mb-2 mr-sm-2 mb-sm-0 pb-4mr" style="width: 100px;" v-model="form.City"></b-form-input></b-col>
                <b-col cols="auto">
                <h3>市預售屋銷售建案聯合稽查紀錄表</h3>
                </b-col>
            </template>
            <template v-else>
                <h3>{{form.City}}市預售屋銷售建案聯合稽查紀錄表</h3>
            </template>
        </b-row>
        <b-row align-h="between">
            <b-col cols="auto" class="pb-2">
            <b-form inline>
                <label for="inline-form-input-name" class="mr-2">查核時間:</label>
                <!-- <span>{{formdata.I_Date}}</span>  -->
                <date-picker v-if="selectedKeys==1" v-model="form.InspectDate" type="datetime" value-type='format'
                format="YYYY-MM-DD HH:mm"></date-picker>
                <label v-else>{{form.InspectDate}}</label>
            </b-form>
            </b-col>
            <b-col cols="auto" class="pb-2">
            <b-form inline>
                <label for="inline-form-input-name" class="mr-2">編號:</label>
                <b-form-input v-if="selectedKeys==1" id="inline-form-input-name-1" class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.Number_Year" :formatter="formatter_year"></b-form-input>
                <label v-else>{{form.Number_Year}}</label>
                <label for="inline-form-input-name" class="mr-2">年度第</label>
                <b-form-input v-if="selectedKeys==1" id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.Number_Key" :formatter="formatter_Key"></b-form-input>
                <label v-else>{{form.Number_Key}}</label>
                <label for="inline-form-input-name" class="mr-2">號</label>
            </b-form>
            </b-col>
        </b-row>
        </div>
        <div class="btn-wrap m-3">
        <div class="text-center">{{ pageName }}</div>
            <b-row>
                <b-col cols="2" align-self="center" class="text-left" v-if="selectedKeys == 1">
                </b-col>
                <b-col cols="2" align-self="center" class="text-left" v-if="selectedKeys != 1"> 
                    <b-button @click="changePage(selectedKeys-1)" variant="outline-primary">上一頁</b-button></b-col>
                <b-col cols="8" align-self="center" class="text-center">
                <template v-for="item in pagedate" >
                        <b-button :key="item.id" pill variant="primary" :disabled="disabled" class="m-1" v-if="selectedKeys == item.id">{{item.id}}</b-button>
                        <b-button :key="item.id" @click="changePage(item.id)" pill variant="outline-secondary" class="m-1" @mouseover="pageName = item.PageName "
                        @mouseleave="pageName = pagedate[selectedKeys-1].PageName" v-else>{{item.id}}</b-button>
                </template>
                </b-col>
                <b-col cols="2" align-self="center" class="text-right" v-if="selectedKeys != 11">
                <b-button @click="changePage(selectedKeys+1)" variant="primary">下一頁</b-button></b-col>
                <b-col cols="2" class="text-right" v-if="selectedKeys == 11">
                    <b-button variant="primary" @click="SendOut">送出</b-button>
                    <!-- <b-modal id="modal-2" hide-footer>
                    <h3 class="my-4 text-center">紀錄表已送出</h3>
                </b-modal> -->
                </b-col>
            </b-row>
        </div>
        <!--page主內容-->
        <Page1Content v-if="selectedKeys==1"></Page1Content>
        <Page2Content v-if="selectedKeys==2"></Page2Content>
        <Page3Content v-if="selectedKeys==3"></Page3Content>
        <Page4Content v-if="selectedKeys==4"></Page4Content>
        <Page5Content v-if="selectedKeys==5"></Page5Content>
        <Page6Content v-if="selectedKeys==6"></Page6Content>
        <Page7Content v-if="selectedKeys==7"></Page7Content>
        <Page8Content v-if="selectedKeys==8"></Page8Content>
        <Page9Content v-if="selectedKeys==9"></Page9Content>
        <Page10Content v-if="selectedKeys==10"></Page10Content>
        <Page11Content v-if="selectedKeys==11"></Page11Content>
        <!-- <div class="innerPageMainContent">
            

        </div> -->

    </b-container>
    </div>
</div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Page1Content from '../components/page1.vue'
import Page2Content from '../components/page2.vue'
import Page3Content from '../components/page3.vue'
import Page4Content from '../components/page4.vue'
import Page5Content from '../components/page5.vue'
import Page6Content from '../components/page6.vue'
import Page7Content from '../components/page7.vue'
import Page8Content from '../components/page8.vue'
import Page9Content from '../components/page9.vue'
import Page10Content from '../components/page10.vue'
import Page11Content from '../components/page11.vue'

export default {
name: 'page1',
components: { 
    DatePicker,
    Page1Content,
    Page2Content,
    Page3Content,
    Page4Content,
    Page5Content,
    Page6Content,
    Page7Content,
    Page8Content,
    Page9Content,
    Page10Content,
    Page11Content,
},
data() {
    return {
    pageName: '基本資料',
    selectedKeys:1,
    date: null,
    disabled:true,
    page: "page1",
    index:null,
    send:false,
    pagedate:[
        {id:1, PageName:"基本資料",PathName:"page1"},
        {id:2, PageName:"建造執照取得情形",PathName:"page2"},
        {id:3, PageName:"預售屋銷售備查及預約單使用情形",PathName:"page3"},
        {id:4, PageName:"廣告內容刊登及資訊揭露情形",PathName:"page4"},
        {id:5, PageName:"預售屋買賣契約書訂定情形",PathName:"page5"},
        {id:6, PageName:"預售屋契約轉讓情形",PathName:"page6"},
        {id:7, PageName:"代銷業及銷售人員執業情形",PathName:"page7"},
        {id:8, PageName:"不動產炒作情形",PathName:"page8"},
        {id:9, PageName:"樣品屋及銷售中心之使用許可情形及其他",PathName:"page9"},
        {id:10, PageName:"補正事項",PathName:"page10"},
        {id:11, PageName:"業者陳述意見及查核結果",PathName:"page11"},
    ],
    yearlist: [],
    show_agencysale: true,
    nowtime: "",
    items: [
        {
        text: "首頁",
        href: "#/Home",
        },
        {
        text: "page1",
        active: true,
        },
    ],
    apiInsertorUpdateInspector: (data) =>
        this.userRequest.post("API/Default/InsertorUpdateInspector", data),
    apiGetFormData: (data) =>
        this.userRequest.post("API/Default/GetFormData", data),
    apiOtherText: (data) =>
        this.userRequest.post("API/Default/OtherTextApi", data),
    apiInspectorRecord: (data) =>
        this.userRequest.post("API/Default/InspectorRecordApi", data),
    apiSalesperson: (data) =>
        this.userRequest.post("API/Default/SalespersonApi", data),
    apiImgFile: (data) =>
            this.userRequest.post("API/Default/ImgFileApi", data),
        
    }
},
computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
    InspectorRecord: function(){
        return this.$store.state.InspectorRecord
    },
    InspectorRecord2: function(){
        return this.$store.state.InspectorRecord2
    },
    Salesperson: function(){
        return this.$store.state.Salesperson
    },
    ImgFile: function(){
        return this.$store.state.ImgFile
    },
},
watch: {
    'selectedKeys'(val){
        this.items[1].text = this.pagedate[val-1].PathName;
        let id = this.$route.query.Id
        if(this.IsNullEmpty(id) && this.form.Form_ID != null){
            id = this.form.Form_ID;
        }
        //console.log("this.$route.query.Id",this.$route.query.Id);
        this.$router.push({ query: { Id:id,index: val} });
    },
},
created() {
    // if(this.$route.query.index != null){
    //     this.selectedKeys = this.$route.query.index;
    // }
    //this.userSignCheck();
    //console.log("this.$route.query.Id",this.$route.query.Id);
    this.FunctionToken(this.init, {Form_ID:this.$route.query.Id});
    if(this.$route.query.Id != null){
        this.FunctionToken(this.initOther,{TYPE:"SELECT",OtherText:{O_Form_ID:this.$route.query.Id}});
        this.FunctionToken(this.initInspectorRecord,{TYPE:"LIST",Form_ID:this.$route.query.Id});
        this.FunctionToken(this.initSalesperson,{TYPE:"LIST",Form_ID:this.$route.query.Id});
        this.FunctionToken(this.initImgFile,{TYPE:"LIST",Form_ID:this.$route.query.Id});
    }
    // this.FunctionToken(this.initOther,{TYPE:"SELECT",OtherText:{O_Form_ID:this.$route.query.Id}})
    // this.FunctionToken(this.initInspectorRecord,{TYPE:"LIST",Form_ID:this.$route.query.Id})
    // this.FunctionToken(this.initSalesperson,{TYPE:"LIST",Form_ID:this.$route.query.Id})
    let index = this.$route.query.index;
    this.selectedKeys = index;
},
mounted() {
},
methods: {
    changeObj(key,evt){
        //console.log("evt",key != null,key)
        if(evt != null){
            this.$store.commit('increment',{key: key ,value :evt})
        }
    },
    formatter_year(e){
    return String(e).substring(0,4);
    },
    formatter_Key(e){
    return String(e).substring(0,20);
    },
    init(data) {
        this.apiGetFormData(data)
            .then((res) => {
            const json = res.data;
            //const jsondata = json.Data;
            if (json.Status) {
                //console.log(json);
                const jsondata = JSON.parse(json.Data);
                //console.log("jsondata",jsondata);
                jsondata.InspectDate = jsondata.InspectDate.replace("T", " ");
                this.$store.commit('updateState',jsondata);
                let obj ={
                    FoundDate: this.IsNull(jsondata.FoundDate) != "" ? this.covertDate(jsondata.FoundDate) : null,
                    ObtainLicenseDate: this.IsNull(jsondata.ObtainLicenseDate) != "" ? this.covertDate(jsondata.ObtainLicenseDate) : null,
                    ReportDate: this.IsNull(jsondata.ReportDate) != "" ? this.covertDate(jsondata.ReportDate) : null,
                    CensorDate: this.IsNull(jsondata.CensorDate) != "" ? this.covertDate(jsondata.CensorDate) : null,
                    SigningDate: this.IsNull(jsondata.SigningDate) != "" ? this.covertDate(jsondata.SigningDate) : null,
                    DForSale_Date: this.IsNull(jsondata.DForSale_Date) != "" ? this.covertDate(jsondata.DForSale_Date) : null,
                    ConsignmentDate_begin: this.IsNull(jsondata.ConsignmentDate_begin) != "" ? this.covertDate(jsondata.ConsignmentDate_begin) : null,
                    ConsignmentDate_until: this.IsNull(jsondata.ConsignmentDate_until) != "" ? this.covertDate(jsondata.ConsignmentDate_until) : null,
                    Alloted_Time: this.IsNull(jsondata.Alloted_Time) != "" ? this.covertDate(jsondata.Alloted_Time) : null,
                }
                this.$store.commit('updateDateTime',obj);
                //console.log("dateTime",this.dateTime)
            }
            })
            .catch((err) => {
            console.log(err);

        });
    },
    initOther(data){
        this.apiOtherText(data)
        .then((res) => {
            const json = res.data;
            //console.log("OtherText",json);
            if (json.Status && json.Data != "null") {
                //console.log("OtherText1",json);
                const jsondata = JSON.parse(json.Data);
                this.$store.commit('updateOtherText',jsondata);
            }
            else{
                this.$store.commit('incrementOtherText',{key: 'O_Form_ID' ,value :this.$route.query.Id});
            }
        })
        .catch((err) => {
            console.log(err);
        });
    },
    initInspectorRecord(data){
        this.apiInspectorRecord(data)
        .then((res) => {
            const json = res.data;
            //console.log("InspectorRecord",json);
            if (json.Status && json.Data != "null") {
                const jsondata = JSON.parse(json.Data);
                console.log("InspectorRecord",jsondata);
                if(jsondata.length > 0){
                    let InspectorRecord = [];
                    let InspectorRecord2 = [];
                    let count =0;
                    let count2 =0;
                    jsondata.forEach(item => {
                        if(item.Listnum == "1"){
                            count++;
                            let obj={
                                id:count,
                                Inspector_NAME:item.Inspect_Name,
                                esign:item.E_signature
                            }
                            InspectorRecord.push(obj);
                        }else{
                            count2++;
                            let obj={
                                id:count,
                                Inspector_NAME:item.Inspect_Name,
                                esign:item.E_signature
                            }
                            InspectorRecord2.push(obj);
                        }
                    });
                    this.$store.commit('updateInspectorRecord',InspectorRecord);
                    this.$store.commit('updateInspectorRecord2',InspectorRecord2);
                }
                
            }
        })
        .catch((err) => {
            console.log(err);
        });
    },
    initSalesperson(data){
        this.apiSalesperson(data)
            .then((res) =>{
                const json = res.data;
                //console.log("Salesperson",json);
                if(json.Status && json.Data != "null"){
                    const jsondata = JSON.parse(json.Data);
                    //console.log("Salesperson",jsondata);
                    if(jsondata.length > 0){
                        this.$store.commit('updateSalesperson',jsondata);
                    }
                }
            })
    },
    initImgFile(data){
        this.apiImgFile(data)
            .then((res) =>{
                const json = res.data;
                //console.log("ImgFile",json);
                if(json.Status && json.Data != "null"){
                    const jsondata = JSON.parse(json.Data);
                    console.log("ImgFile",jsondata);
                    if(jsondata.length > 0){
                        this.$store.commit('incrementImgFile',jsondata);
                    }
                }
            })
    },
    changePage(val){
        this.send = false;
        this.index = val;
        this.saveFormData();
        this.FunctionToken(this.saveOtherText,{TYPE:"UPDATE",OtherText:this.OtherText});
        this.storeInspectorRecord();
        this.storeSalesperson();
    },
    SendOut(){
        this.send = true;
        this.saveFormData();
        this.FunctionToken(this.saveOtherText,{TYPE:"UPDATE",OtherText:this.OtherText});
        this.storeInspectorRecord();
        this.storeSalesperson();
    },
    saveFormData(){
        if(this.form.ADstyle != "" &&  this.form.ADstyle != null){
            this.$store.commit("increment",{key: 'AD_style' ,value :""});
            var obj ="";
            for (var index in this.form.ADstyle) {
                obj += this.form.ADstyle[index];
                if (index < (this.form.ADstyle.length - 1)) {
                    obj += "、";
                }
            }
            this.$store.commit("increment",{key: 'AD_style' ,value :obj});
        }
        if(this.form.SpreadMethodType != "" && this.form.SpreadMethodType != null){
            this.$store.commit("increment",{key: 'SpreadMethod' ,value :""});
            var obj1 = "";
            for (var index1 in this.form.SpreadMethodType) {
                obj1 += this.form.SpreadMethodType[index1];
                if (index1 < (this.form.SpreadMethodType.length - 1)) {
                    obj1 += "、";
                }
            }
            this.$store.commit("increment",{key: 'SpreadMethod' ,value :obj1});
        }
        if(this.form.FakeTransactio_Type != "" && this.form.FakeTransactio_Type != null){
            this.$store.commit("increment",{key: 'FakeTransactionStyle' ,value :""});
            var obj2 = "";
            for (var index2 in this.form.FakeTransactio_Type) {
                obj2 += this.form.FakeTransactio_Type[index2];
                if (index2 < (this.form.FakeTransactio_Type.length - 1)) {
                    obj2 += "、";
                }
            }
            this.$store.commit("increment",{key: 'FakeTransactionStyle' ,value :obj2});
        }
        if(this.form.IllegalSalesChannelsType != "" && this.form.IllegalSalesChannelsType != null){
            this.$store.commit("increment",{key: 'IllegalSalesChannels' ,value :""});
            var obj3 = "";
            for (var index3 in this.form.IllegalSalesChannelsType) {
                obj3 += this.form.IllegalSalesChannelsType[index3];
                if (index3 < (this.form.IllegalSalesChannelsType.length - 1)) {
                    obj3 += "、";
                }
            }
            this.$store.commit("increment",{key: 'IllegalSalesChannels' ,value :obj3});
        }
        if(this.form.MainIllegalSalesType != "" && this.form.MainIllegalSalesType != null){
            this.$store.commit("increment",{key: 'MainIllegalSales' ,value :""});
            var obj4 = "";
            for (var index4 in this.form.MainIllegalSalesType) {
                obj4 += this.form.MainIllegalSalesType[index4];
                if (index4 < (this.form.MainIllegalSalesType.length - 1)) {
                    obj4 += "、";
                }
            }
            this.$store.commit("increment",{key: 'MainIllegalSales' ,value :obj4});
        }
        if(this.form.IllegalSales_type != "" && this.form.IllegalSales_type != null){
            this.$store.commit("increment",{key: 'IllegalSalesStyle' ,value :""});
            var obj5 = "";
            for (var index5 in this.form.IllegalSales_type) {
                obj5 += this.form.IllegalSales_type[index5];
                if (index5 < (this.form.IllegalSales_type.length - 1)) {
                    obj5 += "、";
                }
            }
            this.$store.commit("increment",{key: 'IllegalSalesStyle' ,value :obj5});
        }
        //console.log(this.form);
        this.FunctionToken(this.save, this.form);
    },
    save(data) {
        //console.log(data);
        this.apiInsertorUpdateInspector(data)
        .then((res) => {
            //console.log("res:", res);
            const json = res.data;
            if (json.Status) {
                const jsondata = JSON.parse(json.Data);
                if(this.$store.state.form.Form_ID == null){
                    this.$store.commit("increment",{key: 'Form_ID' ,value :jsondata.Form_ID});
                    this.$store.commit("increment",{key: 'FormCreationDate' ,value :jsondata.FormCreationDate});
                }
                //console.log("send",this.send);
                if(!this.send){
                    this.selectedKeys = this.index;
                }else{                   
                    alert("表單已送出");
                    this.$router.push({ path: "/Home" });
                }
            }
    })
    .catch((err) => {
        //console.log(err);
        alert("發生錯誤");
    });
    },
    saveOtherText(data){
        this.apiOtherText(data)
            .then((res) =>{
                const json = res.data;
                if(json.Status){
                    //console.log("OtherText",json);
                }
            })
    },
    storeInspectorRecord(){
        let Form_ID = this.$route.query.Id;
        let dataList =[];
        let obj;
        //console.log("storeInspectorRecord",this.InspectorRecord ,this.InspectorRecord2 )
        if(this.InspectorRecord.length >0){
            let count =0;
            this.InspectorRecord.forEach(item => {
                if(item.Inspector_NAME == "" ){
                    return;
                }
                count++;
                obj ={
                    Inspect_Form_ID: Form_ID,
                    Inspect_Sort: count,
                    Inspect_Name:item.Inspector_NAME,
                    E_signature:item.esign,
                    Listnum:"1"
                }
                dataList.push(obj);
            });
            
        }
        if(this.InspectorRecord2.length > 0){
            let count =0;
            this.InspectorRecord2.forEach(item => {
                if(item.Inspector_NAME == "" ){
                    return;
                }
                count++;
                obj ={
                    Inspect_Form_ID: Form_ID,
                    Inspect_Sort: count,
                    Inspect_Name:item.Inspector_NAME,
                    E_signature:item.esign,
                    Listnum:"2"
                }
                dataList.push(obj);
            });
        }
        //console.log("InspectorRecord",dataList);
        this.FunctionToken(this.saveInspectorRecord,{TYPE:"INSERT",Form_ID:Form_ID,InspectorRecord:dataList});
    },
    saveInspectorRecord(data){
        this.apiInspectorRecord(data)
            .then((res) =>{
                const json = res.data;
                if(json.Status){
                    //console.log("InspectorRecord",json);
                    //alert("表單已送出");
                    //this.$router.push({ path: "/Home" });
                }
            })
    },
    storeSalesperson(){
        if(this.Salesperson.length > 0){
            let Form_ID = this.$route.query.Id;
            let dataList =[];
            let count = 0;
            this.Salesperson.forEach((item) =>{
                
                if(this.IsNullEmpty(item.NAME)){
                    return;
                }
                else{
                    count++;
                    item.Sort=count;
                    item.Form_ID = Form_ID;
                    dataList.push(item);
                }
            })
            this.FunctionToken(this.saveSalesperson,{TYPE:"INSERT",Form_ID:Form_ID,Salesperson:dataList});
        }
    },
    saveSalesperson(data){
        this.apiSalesperson(data)
            .then((res) =>{
                const json = res.data;
                if(json.Status){
                    //console.log("Salesperson",json);
                    
                }
            })
    },
},
};
</script>
