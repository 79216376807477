<template>
  <div class="innerPage">
    <div class="innerPageHead">
      <b-container>
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-container>
    </div>
    <div class="innerPageContent">
      <b-container fluid>
        <div class="table-responsive">
          <div style="display: flex;justify-content: center;">
            <div style="max-width:1500px;">
              <b-overlay :show="show" rounded="sm" @shown="onShown" @hidden="onHidden">
              <b-row align-h="between" class="pb-4">
                <b-col cols="auto"></b-col>
                <b-col cols="auto">
                  <b-button @click="InsertForm" variant="primary" class="mx-1"><b-icon icon="plus"
                      class="mr-1"></b-icon>新增紀錄表</b-button>
                  <b-button variant="outline-info" class="mx-1" @click="_go('login')">
                    <b-icon icon="power" aria-hidden="true"></b-icon> 登出
                  </b-button> </b-col>
              </b-row>
              <b-table 
                :items="InspectFormList" 
                :fields="window.width <= 1024
                ? formlistanalysis.fields_mobile
                : formlistanalysis.fields
                " :per-page="perPage" :current-page="currentPage" 
                fixed                 
                bordered 
                hover 
                striped
                outlined
                head-variant="dark"
                >
                <template #cell(EDIT)="row" v-if="window.width > 955">
                  <div style="display: flex; flex-flow: wrap;">
                    <b-button ref="show" :disabled="show" @click="gotoCheck(row),show = true" variant="primary" class="mx-1"><b-icon
                      icon="eye-fill" class="mr-1"></b-icon>檢視</b-button>
                    <b-button @click="gotoupdate(row)" variant="info" class="mx-1"><b-icon icon="pencil-square"
                        class="mr-1"></b-icon>編輯</b-button>
                    <b-button variant="success" class="mx-1" @click="printPDF(row)"><b-icon
                        icon="printer-fill" class="mr-1"></b-icon>列印</b-button>
                    <b-button @click="deleteform(row)" variant="danger" class="mx-1"><b-icon icon="trash-fill"
                        class="mr-1"></b-icon>刪除</b-button>
                  </div>
                </template>
                <template #cell(EDIT)="row" v-else>
                  <div style="display: flex; flex-flow: wrap;">
                  <b-button ref="show" @click="gotoCheck(row)" variant="primary" style="height: 40px; width:40px; padding: 0;"><b-icon
                      icon="eye-fill" style="margin: 0;"></b-icon></b-button>
                  <b-button @click="gotoupdate(row)" variant="info" style="height: 40px; width:40px; padding: 0;"><b-icon icon="pencil-square"
                      ></b-icon></b-button>
                  <b-button variant="success" style="height: 40px; width:40px; padding: 0;" @click="printPDF(row)"><b-icon
                      icon="printer-fill"></b-icon></b-button>
                  <b-button @click="deleteform(row)" variant="danger" style="height: 40px; width:40px; padding: 0;"><b-icon icon="trash-fill"
                      ></b-icon></b-button>
                    </div>
                </template>
              </b-table>
                <template #overlay>
                  <div class="text-center">
                    <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                    <p id="cancel-label">Loading，Please wait...</p>
                  </div>
                </template>
              </b-overlay>
            </div>
            
          </div>
          
        </div>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
          aria-controls="my-table"></b-pagination>
      </b-container>
      
    </div>
      
        
  </div>
</template>
<script>
//import VueOfficePdf from '@vue-office/pdf'
//import '@vue-office/docx/lib/index.css'
import 'print-js/dist/print.css';
import print from "print-js"

export default {
  name: "Home",
  components: {
    //VueOfficePdf
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      docx: "",
      docxurl: "",
      detail:"",
      navigationActive: [],
      InspectFormList: [],
      Pshow: false,
      show: false,
      isMobile:false,
      str: [],
      window: {
        width: 1024,
        height: 0,
      },
      items: [
        {
          text: "首頁",
          href: "#/Home",
        },
      ],
      formlistanalysis: {
        fields: [
          {
            key: "Form_ID",
            label: "紀錄表編號",
            visible: true,
            thStyle: "width: 15%; ",
            //thStyle: "width: 200px;",
          },
          {
            key: "strr",
            label: "查核建案/建商/代銷名稱",
            visible: true,
            thStyle: "overflow:hidden;",
          },
          {
            key: "InspectDate",
            label: "查核時間",
            visible: true,
            thStyle: "width: 15%; ",
            //thStyle: "width: 250px;",
          },
          {
            key: "Name",
            label: "承辦人員",
            visible: true,
            thStyle: "width: 6%; ",
            //thStyle: "width: 100px;",
          },
          {
            key: "EDIT",
            label: "",
            visible: true,
            //thStyle: "width: 25%; ",
          },
        ],
        fields_mobile: [
          {
            key: "Form_ID",
            label: "編號",
            visible: true,
            thStyle: "width: 15%;",
          },
          {
            key: "strr",
            label: "查核建案",
            visible: true,
            //thStyle: "width: 25%;",
          },
          {
            key: "EDIT",
            label: "",
            visible: true,
          },
        ],
      },
      apiSearchList: (data) =>
        this.userRequest.post("API/Default/SearchList", data),
      apiDeleteInspectorForm: (data) =>
        this.userRequest.post("API/Default/DeleteInspectorForm", data),
      apiwriteword: (data) =>
        this.userRequest.post("API/Default/WriteWord", data),
      apiGetCheckData: (data) =>
        this.userRequest.post("API/Default/GetCheckData", data),
      apiGetFormData: (data) =>
        this.userRequest.post("API/Default/GetFormData", data),
    };
  },
  computed:{
    form: function(){
        return this.$store.state.form
    },
    dateTime: function(){
        return this.$store.state.dateTime
    },
    OtherText: function(){
        return this.$store.state.OtherText
    },
    InspectorRecord: function(){
        return this.$store.state.InspectorRecord
    },
    InspectorRecord2: function(){
        return this.$store.state.InspectorRecord2
    },
    Salesperson: function(){
        return this.$store.state.Salesperson
    },
  },
  created() {
    this.userSignCheck();
    this.FunctionToken(this.getformlist, {});
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this._isMobile();
  },
  mounted() { },
  methods: {
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if(!flag){
        //alert("PC端");
        this.isMobile = false;
      }
      else{
        //alert("手機端");
        this.isMobile = true;
      }
    },
    printPDF(item) {
      this.FunctionToken(this.writePDF,item.item);
      this.show = true;
      //this.$print({ printable: 'https://easymap.tw/tc_lab//uplode/%e9%a0%90%e5%94%ae%e5%b1%8b%e8%81%af%e5%90%88%e7%a8%bd%e6%9f%a5%e7%b4%80%e9%8c%84%e8%a1%a8.pdf', type: 'pdf' }) //線上機
    },
    dataURLtoBlob(dataUrl) {
      var bstr = atob(dataUrl);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: 'application/pdf' });
    },
    print() {
      //this.Pshow=true;
      //let blob = this.dataURLtoBlob(this.docx);
      var date = (new Date()).getTime();
      var ifr = document.createElement('iframe');
      ifr.style.frameborder = 'no';
      ifr.style.display = 'none';
      ifr.style.pageBreakBefore = 'always';
      ifr.setAttribute('id', 'printPdf' + date);
      ifr.setAttribute('name', 'printPdf' + date);
      ifr.src = this.docx;
      document.body.appendChild(ifr);
      this.doPrint('printPdf' + date);
      window.URL.revokeObjectURL(ifr.src); // 釋放URL 物件
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    getformlist(data) {
      this.apiSearchList(data)
        .then((res) => {
          const json = res.data;
          //console.log("json:",json);
          if (json.Status) {
            let strr = null
            this.str = [];
            let temp = "";
            this.InspectFormList = [],
              this.InspectFormList = JSON.parse(json.Data);
            for (var index in this.InspectFormList) {
              strr = this.InspectFormList[index].BuildCase;
              if (this.InspectFormList[index].BuilderName != null && this.InspectFormList[index].BuilderName != "")
                strr += "/" + this.InspectFormList[index].BuilderName;
              if (this.InspectFormList[index].AgencySales_Name != null && this.InspectFormList[index].AgencySales_Name != "")
                strr += "/" + this.InspectFormList[index].AgencySales_Name;
              this.str.push();
              this.InspectFormList[index].strr = strr;
            }
            //console.log("InspectFormlist", this.InspectFormList);
            //console.log("InspectFormlist.length", this.InspectFormList.length);
            this.totalRows = this.InspectFormList.length;
            //console.log("this.str",this.str);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoupdate(item) {
      console.log("item", item);
      //this.$store.commit('resetState',this.form);
      //this.FunctionToken(this.update, item.item);
      this.$router.push({ path: "/FormData",query:{Id:item.item.Form_ID,index:1} });
    },
    update(data) {
      this.apiGetFormData(data)
        .then((res) => {
          const json = res.data;
          //const jsondata = json.Data;
          if (json.Status) {
            //console.log(json);
            const jsondata = JSON.parse(json.Data);
            console.log("jsondata",jsondata);
            this.$store.commit('updateState',jsondata);
            //console.log("form",this.form)
            this.$router.push({ path: "/FormData",query:{Id:1} });
          }
        })
        .catch((err) => {
          console.log(err);

        });
    },
    deleteform(item) {
      if (this.IsNull(item.item["Form_ID"]) != "")
        this.FunctionToken(this.delete, item.item);
    },
    delete(data) {
      this.apiDeleteInspectorForm(data)
        .then((res) => {
          const json = res.data;
          if (json.Status) {
            //console.log("form was deleted FormID:",data.Form_ID);
            this.FunctionToken(this.getformlist, {});
          }
        })
        .catch((err) => {
          console.log(err);

        });
    },
    resetData(){
      this.$store.commit('resetState',this.form);
      this.$store.commit('resetDateTime',this.dateTime);
      this.$store.commit('resetInspectorRecord',this.InspectorRecord);
      this.$store.commit('resetInspectorRecord2',this.InspectorRecord2);
      this.$store.commit('resetSalesperson',this.Salesperson);
    },
    InsertForm() {
      if(this.form.Form_ID != null){
        this.resetData();
      }
      this.$router.push({ path: "/FormData",query: { index: 1} });
      //this.$router.push({ path: "/FormData",query: { index: 1} });
    },
    gotoCheck(item) {
      this.resetData();
      this.FunctionToken(this.getCheckData, item.item);
      this.FunctionToken(this.writeword, item.item);
      this.show = true;
      //this.writword(item.Form_ID);
    },
    getCheckData(data) {
      this.apiGetCheckData(data)
        .then((res) => {
          const json = res.data;
          if (json.Status) {
            //console.log("CheckData:",JSON.stringify(json.Data));
            sessionStorage.setItem("CheckData", json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });//
    },
    writeword(data) {
      this.apiwriteword(data)
        .then((res) => {
          const json = res.data;
          const jsondata = JSON.parse(json);
          if (jsondata.Status) {
            //console.log("jsonData:", JSON.stringify(jsondata.Data));
            sessionStorage.setItem("filepath", jsondata.Data);
            this.$router.push({ path: "/Check" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    writePDF(data) {
      this.apiwriteword(data)
        .then((res) => {
          const json = res.data;
          const jsondata = JSON.parse(json);
          if (jsondata.Status) {
            //console.log("jsonData:", JSON.stringify(jsondata.Data));
            sessionStorage.setItem("filepath", jsondata.Data);
            //this.$print({ printable: 'http://192.168.7.137:8081/uplode/%e9%a0%90%e5%94%ae%e5%b1%8b%e8%81%af%e5%90%88%e7%a8%bd%e6%9f%a5%e7%b4%80%e9%8c%84%e8%a1%a8.pdf', type: 'pdf' });
            var str = JSON.parse(sessionStorage.getItem("filepath"));
            //console.log("str", str.Path);
            //this.docxurl = "http://192.168.7.137:8081/uplode/%e9%a0%90%e5%94%ae%e5%b1%8b%e8%81%af%e5%90%88%e7%a8%bd%e6%9f%a5%e7%b4%80%e9%8c%84%e8%a1%a8.pdf";
            //this.docxurl = "https://easymap.tw/tc_lab/uplode/%e9%a0%90%e5%94%ae%e5%b1%8b%e8%81%af%e5%90%88%e7%a8%bd%e6%9f%a5%e7%b4%80%e9%8c%84%e8%a1%a8.pdf";
            this.docxurl = str.Path;
            if(this.isMobile){
              //window.open(docxurl, _blank);
              window.location.href =this.docxurl;
            }
            else{
              this.$print({ printable: this.docxurl, type: 'pdf',onPdfOpen:true,showModal:true});
            }
            //this.$print({ printable: this.docxurl, type: 'pdf',onPdfOpen:true});
            this.show =false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rendered() {
      console.log("渲染完成")
    },
    onShown() {
        // Focus the cancel button when the overlay is showing
        
    },
      onHidden() {
        // Focus the show button when the overlay is removed
        this.$refs.show.focus()
    }
  },
};

</script>




